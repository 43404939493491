import * as React from 'react';

import Layout from '../../components/layout';
import AustinMathisHeadshot from '../../images/austin-mathis-headshot';
import SachVideo from '../../images/3Sach-food-video';
import CSCSieves from '../../images/csc-sieves';
import RickHeadshot from '../../images/rick-headshot-large';
import ChrisHeadshot from '../../images/chris-headshot';

export default () => (
  <Layout
    seoTitle="Monterey Software | Blog"
    seoDescription="Advice and success stories for small and medium businesses thinking about switching to an ERP system"
    seoImage="/img/austin-mathis-headshot.jpg"
  >
    <div className="relative px-4 pt-16 pb-20 bg-gray-50 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            From the blog
          </h2>
          <p className="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            Advice and success stories for small and medium businesses thinking
            about switching to an ERP system
          </p>
        </div>
        <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <a href="/blog/empire-candle/">
                <AustinMathisHeadshot className="object-cover w-full h-64" />
              </a>
            </div>
            <div className="flex flex-col justify-between flex-1 p-6 bg-white">
              <div className="flex-1">
                <p className="text-sm font-medium leading-5 text-teal-600">
                  <a href="/blog/empire-candle/" className="hover:underline">
                    Article
                  </a>
                </p>
                <a href="/blog/empire-candle/" className="block">
                  <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
                    Candle manufacturer uses ERP to eliminate costly mistakes
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    “Even as a small business, it’s incredible how much you can
                    miss in terms of inventory and supplies. These small misses
                    add up. In our case, these misses were severely affecting
                    our profits.”
                  </p>
                </a>
              </div>
              <div className="flex items-center mt-6">
                <div className="flex-shrink-0">
                  <a href="#">
                    <RickHeadshot className="w-10 h-10 rounded-full" />
                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium leading-5 text-gray-900">
                    <a href="#" className="hover:underline">
                      Rick Saenz
                    </a>
                  </p>
                  <div className="flex text-sm leading-5 text-gray-500">
                    <time dateTime="2020-03-16">Oct 22, 2020</time>
                    <span className="mx-1">·</span>
                    <span>6 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <a href="/blog/3Sach-food">
                <SachVideo className="object-cover w-full h-64" />
              </a>
            </div>
            <div className="flex flex-col justify-between flex-1 p-6 bg-white">
              <div className="flex-1">
                <p className="text-sm font-medium leading-5 text-teal-600">
                  <a href="/blog/3Sach-food/" className="hover:underline">
                    Case study (video)
                  </a>
                </p>
                <a href="/blog/3Sach-food/" className="block">
                  <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
                    Vietnamese chain of gourmet food shops adopts Odoo
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    How 3Sach Food in Vietnam worked with an Odoo partner to do
                    a phased implentation: (1) procurement, inventory
                    management, point of sales, sales & invoicing; (2)
                    manufacturing & accounting; (3) HR, marketing, & online
                    sales.
                  </p>
                </a>
              </div>
              <div className="flex items-center mt-6">
                <div className="flex-shrink-0">
                  <a href="#">
                    <ChrisHeadshot className="w-10 h-10 rounded-full" />
                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium leading-5 text-gray-900">
                    <a href="#" className="hover:underline">
                      Chris Saenz
                    </a>
                  </p>
                  <div className="flex text-sm leading-5 text-gray-500">
                    <time dateTime="2020-03-16">Oct 12, 2020</time>
                    <span className="mx-1">·</span>
                    <span>6 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <a href="/blog/csc-manufacturing">
                <CSCSieves className="object-cover w-full h-64" />
              </a>
            </div>
            <div className="flex flex-col justify-between flex-1 p-6 bg-white">
              <div className="flex-1">
                <p className="text-sm font-medium leading-5 text-teal-600">
                  <a href="#" className="hover:underline">
                    {' '}
                    Case Study
                  </a>
                </p>
                <a href="/blog/csc-manufacturing" className="block">
                  <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
                    Manufacturer saves $25K/year by switching from Netsuite to
                    Odoo
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    CSC Scientific was not happy with Netsuite, so they went
                    looking for something newer with a more flexible workflow.
                    For them Odoo was a much better solution, and only one-fifth
                    the cost.
                  </p>
                </a>
              </div>
              <div className="flex items-center mt-6">
                <div className="flex-shrink-0">
                  <a href="/blog/csc-manufacturing">
                    <RickHeadshot className="w-10 h-10 rounded-full" />
                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium leading-5 text-gray-900">
                    <a href="#" className="hover:underline">
                      Rick Saenz
                    </a>
                  </p>
                  <div className="flex text-sm leading-5 text-gray-500">
                    <time dateTime="2020-03-16">Aug 15, 2020</time>
                    <span className="mx-1">·</span>
                    <span>6 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
